import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';


// Components
import Home from "./Home";
import Resume from "./Resume";
// import Footer from "./common/Footer";

// Styles
import "./styles/App.css";

function App() {
  // const history = createBrowserHistory();

  // const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  // if (path) {
  //   history.replace(path);
  // }

  const trackingId = "UA-150650749-1"; 
  ReactGA.initialize(trackingId);
  ReactGA.set({
    // userId: auth.currentUserId(),
    userId: 'anon',
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  })

  let history = createBrowserHistory();
  
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <div className="App">
      <Router history={history}>
        <main className="main">
          {/* <Header></Header> */}
          <Route path="/" exact component={Home} />
          <Route path="/resume" component={Resume} />
          {/* <Footer></Footer> */}
        </main>
      </Router>
    </div>
  );
}

export default App;
