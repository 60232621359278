import React from "react";

function Home() {
  let year = new Date().getFullYear();
  return (
    <div>
      <div id="home">
        <h1>bryanbrinson.info</h1>
        <div
          class="LI-profile-badge"
          data-version="v1"
          data-size="large"
          data-locale="en_US"
          data-type="horizontal"
          data-theme="dark"
          data-vanity="bryanbrinson"
        >
          <a
            class="LI-simple-link"
            href="https://www.linkedin.com/in/bryanbrinson?trk=profile-badge"
          >
            Bryan Brinson
          </a>
        </div>
      </div>
      <div>
        <small>
          <em>©{year}</em>
        </small>
      </div>

      <script src="https://www.google.com/recaptcha/api.js?render=6LeTUuEUAAAAAET7erX2PNa9LrBAol2F7cCn0A0S"></script>
      {/* <script>
        grecaptcha.ready(function() {
            grecaptcha.execute('6LeTUuEUAAAAAET7erX2PNa9LrBAol2F7cCn0A0S', {action: 'homepage'}).then(function(token) {
              ...
            });
        });
      </script> */}
    </div>
  );
}

export default Home;
